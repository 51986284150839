import useConnector from "@app-connector";
import Languages from "@app-i18n/languages.json" assert { type: "json" };
import { useAppRestClient } from "@app-shell-rest/configurations";
import { useGlobalStore } from "@app-shell-store/global";
import { useLangStore } from "@app-shell-store/lang";
import { useUserStore } from "@app-shell-store/user";
import useEventsManager from "@app-utilities/events-manager";
import { showModalError } from "@app-utilities/modals";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import usePersistentStorage from "@app-utilities/persistent-storage";
import { connect, getNativeWebView } from "@qamf/conqueror-native";
import type { ITranslation } from "@qamf/xliff-loader";
import { useDebounceFn, useNavigatorLanguage } from "@vueuse/core";
import type { RestError } from "scarlett";
import { ref } from "vue";
import type { RouteRecordRaw } from "vue-router";

import QDeskApp from "./main";
import registerServiceWorker from "./registerServiceWorker";
import router, { type AppRoute } from "./router";

type ModuleId = "lane-options" | "neoverse";
type Module = {
	id: ModuleId;
	title: string;
	path: `/${string}`
}
export const modules: Module[] = [
	{
		id: "lane-options",
		title: "Lane Options",
		path: "/lane-options"
	},
	{
		id: "neoverse",
		title: "Neoverse",
		path: "/neoverse"
	}
];

async function resolveAppContext() {
	const { useSystemStore, initAppSettings, useLocalBackend } = useConnector();

	await initAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" })
				.then((data) => data.json())
				.catch((error) => {
					console.error(error);
					showModalError("Error", "File not found");
				});

			return response;
		}
	});

	const isNative = Boolean(getNativeWebView());
	if (isNative) {
		const conqueror = connect();
		const [centerId, getLocalBackendBaseUrl] = await Promise.all([
			conqueror.settings.getCenterId(),
			conqueror.settings.getLocalBackendBaseUrl()
		]);
		const { setId: setSystemId } = useSystemStore();
		setSystemId(+centerId);
		const { setOption } = useLocalBackend();
		setOption("host", getLocalBackendBaseUrl);
	}
}

async function setupObservability() {
	const { useAppSettings, useObservability, useSystemStore } = useConnector();
	const { setup, setCommonPropsResolver } = useObservability();
	const { appInsightsConnString } = useAppSettings();
	const isNative = Boolean(getNativeWebView());

	setup({
		config: {
			connectionString: appInsightsConnString,
			maxAjaxCallsPerView: -1,
			disableExceptionTracking: true,
			disableTelemetry: __LAUNCH_MODE__ !== "Standard"
		}
	});
	setCommonPropsResolver(function commonObservabilityProps() {
		const route = router.currentRoute;
		const { launchMode, version, channel, environment, cloudBackendUrl } = useAppSettings();
		const { userDetails, userFunctionalities } = useUserStore();
		const { Id } = useSystemStore();
		const { isStandalone } = useGlobalStore();
		const { getItem } = usePersistentStorage();
		const terminalNumber = getItem("terminalNumber");
		const mode = isNative ? "Native" : isStandalone ? "Standalone" : "Browser";

		const commonProperties = {
			routePath: route.value?.fullPath ?? "",
			routeName: route.value?.name ?? "",
			launchMode,
			channel,
			environment,
			version,
			mode,
			cloudBackendUrl,
			user: userDetails,
			functionalities: userFunctionalities,
			systemId: Id.value,
			terminalNumber
		};
		return commonProperties;
	});
}

function initErrorHandling() {
	const fatalErrors: any[] = [];
	const logErrors = useDebounceFn(() => {
		const printErrorEl = (el: any, title?: string) => {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", title ?? el.error.message);
			console.info(el.datetime);
			console.error(el.error);
			if (el.extras)
				console.info(el.extras);
			console.groupEnd();
		};
		if (fatalErrors.length === 1)
			printErrorEl(fatalErrors[0], "A fatal error occurred");

		else {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", `Intercepted fatal errors: ${fatalErrors.length}`);
			fatalErrors.forEach(er => printErrorEl(er));
			console.groupEnd();
		}
	}, 1000);
	const { useObservability } = useConnector();
	const { trackException, trackEvent } = useObservability();
	const { onError, emitError } = useEventsManager();
	const { setIsCloudOffline } = useGlobalStore();

	onError(async(error, extras) => {
		const tracked = await trackException(error, extras);
		fatalErrors.push({ error, extras, datetime: new Date() });
		logErrors();
		trackEvent(AppInsightsEvent.FatalError, {
			description: "Unhandled exception feedback to user",
			error: tracked?.exception,
			errorName: tracked?.exception.name,
			errorStack: tracked?.exception.stack,
			errorMessage: tracked?.exception.message,
			errorExtras: tracked?.properties
		}, true);

		const statusCode = (tracked?.exception as RestError)?.statusCode;
		const isBadGateway = statusCode === 502;
		const isServiceUnavailable = statusCode === 503;
		const isGatewayTimeout = statusCode === 504;
		const cloudIsDown = isBadGateway || isServiceUnavailable || isGatewayTimeout;
		setIsCloudOffline(cloudIsDown);

		if (!cloudIsDown)
			showModalError("Fatal Error", `Unhandled exception: ${tracked?.exception.message}`);
	});
	window.onerror = (message, source, lineno, colno, error) => {
		if (!error) return;

		emitError(error, { message, source, lineno, colno });
		return true;
	};
	window.onunhandledrejection = event => {
		event.preventDefault();
		if (event.reason instanceof Error) emitError(event.reason);
		else emitError(new Error("Promise Rejection"), event.reason);
	};
	QDeskApp.config.errorHandler = (err: any, instance: any, info: any) => {
		emitError(err as Error, { info });
	};
}

async function initMultiLanguage() {
	const { useI18n } = useConnector();
	const { setup: setupI18n, setLanguage, getIsoCode } = useI18n();
	const { setupI18nApp } = useLangStore();

	setupI18n<ITranslation>({
		languages: Languages,
		languageFetcher(langCode) {
			console.log(`[i18n] Setting lang code: ${langCode}`);
			switch (langCode) {
				case "de-DE":
					return import("@qamf/qst-qdesk/de-DE/qdesk.xlf");
				case "fr-FR":
					return import("@qamf/qst-qdesk/fr-FR/qdesk.xlf");
				case "it-IT":
					return import("@qamf/qst-qdesk/it-IT/qdesk.xlf");
				case "es-ES":
					return import("@qamf/qst-qdesk/es-ES/qdesk.xlf");
				case "nb-NO":
					return import("@qamf/qst-qdesk/nb-NO/qdesk.xlf");
				case "sv-SE":
					return import("@qamf/qst-qdesk/sv-SE/qdesk.xlf");
				case "da-DK":
					return import("@qamf/qst-qdesk/da-DK/qdesk.xlf");
				case "id-ID":
					return import("@qamf/qst-qdesk/id-ID/qdesk.xlf");
				case "nl-NL":
					return import("@qamf/qst-qdesk/nl-NL/qdesk.xlf");
				case "ja-JP":
					return import("@qamf/qst-qdesk/ja-JP/qdesk.xlf");
				default:
					return import("./i18n/locale.xlf");
			}
		},
		translateKeyResolver(repo, key) {
			if (!repo) {
				console.warn(`[i18n] The Translation-Repository is not defined. Returning the key as is: ${key}`);
				return key;
			}
			let translateKey = "";

			if (key in repo.target && repo.target[key])
				translateKey = repo.target[key].trim();
			else if (key in repo.source && repo.source[key])
				translateKey = repo.source[key].trim();
			else {
				console.warn(`[i18n] The Translation-Key '${key}' was not translated`);
				translateKey = `[${key.toUpperCase()}]`;
			}
			if (!translateKey)
				console.warn(`[i18n] The Translation-Key '${key}' is empty.`);
			return translateKey;
		}
	});
	const langToSet = useNavigatorLanguage().language.value ?? "en-US";
	await setLanguage(langToSet);
	await setupI18nApp(getIsoCode());
}

export const routesRegistered = ref<RouteRecordRaw[]>([]);

async function checkTerminalPairing() {
	const { getItem, clear } = usePersistentStorage();
	const systemId = getItem("systemId");
	if (!systemId) {
		console.warn("Pairing failed. System ID not found.");
		return;
	}

	const terminalNumber = getItem("terminalNumber");
	if (!terminalNumber) {
		console.warn("Pairing failed. Terminal number not found.");
		return;
	}

	const { getCenterInfo } = useAppRestClient();
	const centerResponse = await getCenterInfo(systemId, terminalNumber);
	if (!centerResponse.fetchResponse?.ok || !centerResponse.data) {
		clear();
		return;
	}

	const { useSystemStore, useI18n } = useConnector();
	const { setId } = useSystemStore();
	const { setLanguage, getIsoCode } = useI18n();
	const { setupI18nApp } = useLangStore();

	setId(systemId);

	const companyLanguage = centerResponse.data.CompanyLanguage ?? "en-US";
	await setLanguage(companyLanguage);
	await setupI18nApp(getIsoCode());
}

const getNeoverseConfig = async() => {
	const { useSystemStore, use4DAgent } = useConnector();
	const { Id } = useSystemStore();
	const { setOption } = use4DAgent();
	const { getNeoverseCloudConfiguration } = useAppRestClient();
	const { setItem } = usePersistentStorage();

	if (!Id.value)
		throw new Error("Missing SystemId on getNeoverseConf");

	const response = await getNeoverseCloudConfiguration(Id.value);
	if (!response.fetchResponse?.ok || !response.data)
		return console.warn(response.error?.code);

	setOption("host", `https://${response.data.LanIpAddress}:5253`);
	setItem("terminalAuthInfo", {
		LanIpAddress: response.data.LanIpAddress
	});
};

const beforeModuleRoutes: Partial<Record<ModuleId, ()=> void>> = {
	neoverse: async() => {
		await getNeoverseConfig();
	}
};

async function registerModules() {
	const isNative = Boolean(getNativeWebView());
	const { registerModule, useObservability } = useConnector();
	const { trackEvent } = useObservability();

	for (const module of modules) {
		trackEvent(AppInsightsEvent.ModuleRequest, { moduleId: module.id });
		const [moduleImport] = await Promise.all([
			import(`@qamf/module-${module.id}/dist/index`),
			import(`@qamf/module-${module.id}/dist/module.css`)
		]);
		trackEvent(AppInsightsEvent.ModuleDownloaded, { moduleId: module.id });

		trackEvent(AppInsightsEvent.ModuleSetupStart, { moduleId: module.id });
		const { component } = await registerModule({
			appName: "QDesk",
			appVersion: __VERSION__,
			basePath: module.path,
			useConnector
		}, moduleImport);

		const routeModule: AppRoute = {
			path: module.path,
			name: `module-${module.id}`,
			meta: {
				title: module.title,
				showIf: module.id === "lane-options" ? () => isNative : undefined,
				onBeforeEachModuleRoute: beforeModuleRoutes[module.id]
			},
			component
		};
		router.addRoute(routeModule);

		trackEvent(AppInsightsEvent.ModuleSetupEnd, { moduleId: module.id });

		routesRegistered.value.push(routeModule);
	}
}

export default async function boot() {
	await useGlobalStore().initPwaEvents();
	await resolveAppContext();
	await setupObservability();
	initErrorHandling();
	await initMultiLanguage();
	registerServiceWorker();
	await registerModules();
	await checkTerminalPairing();
}
