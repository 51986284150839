<template>
	<div>
		<div class="px-4 py-5 my-5 text-center">
			<div class="col-lg-6 mx-auto">
				<h1 class="display-6 fw-bold">
					{{ translateKey("access_denied") }}
				</h1>
			</div>
		</div>
	</div>
</template>
<script lang="ts" src="./access-denied.ts"></script>
