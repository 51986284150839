import { getNativeWebView } from "@qamf/conqueror-native";
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "Home",
	components: {},
	setup() {
		const router = useRouter();
		const isNative = Boolean(getNativeWebView());

		onBeforeMount(() => {
			if (!isNative)
				router.push({ path: "/neoverse" });
		});
	}
});
