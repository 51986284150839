import usePersistentStorage from "@app-utilities/persistent-storage";
import { createRestClient } from "scarlett";

const useRestClientBase = createRestClient({
	host: "",
	basePath: "",
	throw: true,
	credentials: "omit",
	mode: "cors",
	headers: new Headers({
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*"
	}),
	responseType: (req, res) => {
		if (res?.status === 500)
			return "text";

		return "json";
	}
});
// FIXME: Temporary agent4D to be moved to module-connector
// Tech Story 59578: [QDesk] Move agent4D rest to module-connector
const use4DAgent = () => {
	const storageTerminalAuthInfo = usePersistentStorage().getItem("terminalAuthInfo");
	const agent4DUrl = storageTerminalAuthInfo?.LanIpAddress ?? null;
	const baseClient = useRestClientBase();

	const usedHost = baseClient.getOption("host");
	if (!usedHost) {
		const host = __AGENT_4D_URL__ || agent4DUrl || "";
		baseClient.setOption("host", host);
	}

	return baseClient;
};

export default use4DAgent;
