<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="terminal-pairing container-fluid">
		<div class="row vh-100">
			<div class="col-lg-8 qubica-bg" />
			<div class="col-lg-4 p-3 d-flex justify-content-center align-items-center">
				<div class="d-block py-0 px-5">
					<div class="instructions">
						<template v-if="!isTerminalPaired">
							<h1>
								{{ translateKey("pair_your_system_to_this_terminal") }}
							</h1>
							<p class="py-3">
								{{ translateKey("follow_instructions_and_keep_page_open") }}
							</p>
							<div class="instructions pb-2">
								<ol>
									<li v-html="instructionsQPortal()" />
									<li>{{ translateKey("pairing_instructions_step_2_close_modal") }}</li>
									<li>{{ translateKey("pairing_instructions_step_3_terminal") }}</li>
									<li>{{ translateKey("pairing_instructions_step_4_pair") }}</li>
								</ol>
							</div>
							<div class="pt-5">
								<QButton
									v-if="!deviceInfo"
									class="d-flex mx-auto"
									@click="getPairingCode"
								>
									<template v-if="!isLoading">
										{{ translateKey("get_pairing_code") }}
									</template>
									<QSpinner
										v-else
										inline
										small
									/>
								</QButton>
								<div
									v-if="deviceInfo"
									class="text-center"
								>
									<div class="pairing-code">
										<QButton
											variant="outline-secondary"
											class="d-flex mx-auto"
											@click="copyUserCode"
										>
											<div
												v-if="!isLoading"
												class="user-select-none"
											>
												{{ deviceInfo.user_code }}
												<i class="bi bi-copy ms-2" />
											</div>
											<QSpinner
												v-else
												inline
												small
											/>
										</QButton>
									</div>
									<p class="text-center mb-0 mt-2">
										<template v-if="!currentTimeCode">
											{{ translateKey("pairing_code_has_expired") }}
										</template>
										<template v-else>
											{{ translateKey("this_code_expire_in_time", {expiringTime: formatTimerCountdown() }) }}
										</template>
									</p>
									<a
										href="#"
										@click.prevent="generateNewPairingCode"
									>
										{{ translateKey("get_new_code") }}
									</a>
								</div>
							</div>
						</template>
						<template v-else>
							<h1>
								{{ translateKey("you_are_all_set") }}
							</h1>
							<div class="py-3">
								<p>{{ labelTerminalPaired() }}</p>
								<p class="mb-0">
									{{ labelSystemInfo() }}
								</p>
							</div>
							<QButton
								class="d-flex mx-auto mt-5"
								@click="onNextButtonClick"
							>
								{{ translateKey("next") }}
							</QButton>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" src="./terminal-pairing.ts"></script>
<style lang="scss">
.terminal-pairing {
	.box-instructions {
		padding: 0 var(--spacer-7);
	}
	.pairing-code {
		.btn {
			opacity: var(--q-opacity-100);
			-webkit-user-select: text;
			-moz-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}
	}
}
</style>
