import useConnector from "@app-connector";
import { useUserStore } from "@app-shell-store/user";
import { QOverlay } from "@qamf/lighthouse";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "Login",
	components: {
		QOverlay
	},
	setup() {
		const { useAuthentication } = useConnector();
		const { login, isAuthenticated } = useAuthentication();
		const userStore = useUserStore();
		const isViewLoading = ref(false);
		const router = useRouter();

		onMounted(async() => {
			isViewLoading.value = true;

			const isLogged = await isAuthenticated();
			if (!isLogged) await login();

			await Promise.all([
				userStore.syncUserState(),
				userStore.syncUserInfo()
			]);

			await router.push({ name: "home" });
		});

		return {
			isViewLoading
		};
	}
});
