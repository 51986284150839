import { createRestClient } from "scarlett";

import useConnector from "../index";

const useRestClientBase = createRestClient({
	host: "",
	basePath: "",
	throw: true,
	credentials: "omit",
	mode: "cors",
	headers: new Headers({
		"Content-Type": "application/json",
		"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA"
	}),
	responseType: (_, res) => {
		if (res?.status === 500)
			return "text";

		return "json";
	}
});

const useLocalBackend = () => {
	const { useSystemStore } = useConnector();
	const { Id } = useSystemStore();
	const baseClient = useRestClientBase();
	baseClient.setOption("basePath", `/api/QDesk/${Id.value}`);
	return baseClient;
};

export default useLocalBackend;
