import { AppInsightsEvent } from "@app-utilities/observability-events";
import { QFormFieldInput, QModal } from "@qamf/lighthouse";
import { computed, defineComponent, onBeforeMount, ref } from "vue";

import useConnector from "../../connector";

export default defineComponent({
	name: "QSettings",
	components: {
		QFormFieldInput,
		QModal
	},
	props: {
		modelValue: { required: true, type: Boolean }
	},
	emits: ["update:modelValue"],

	setup(props, { emit }) {
		const { useLocalBackend, useI18n, useSystemStore, useObservability, useCloudBackend } = useConnector();

		if (!useCloudBackend) throw new Error("Cloud rest client was not defined on connector");

		if (!useLocalBackend) throw new Error("LocalBackend rest client was not defined on connector");

		const { setOption: setCloudBackendOption, getOption: getCloudBackendOption } = useCloudBackend();
		const { setOption: setLocalBackendOption, getOption: getLocalBackendOption } = useLocalBackend();
		const { Id: storeSystemId, setId: setStoreSystemId } = useSystemStore();
		const { trackEvent } = useObservability();
		const { translateKey } = useI18n();

		const cloudBackendUrl = ref(getCloudBackendOption("host"));
		const localBackendUrl = ref(getLocalBackendOption("host"));
		const localSystemId = ref(storeSystemId.value);

		const initialValues = ref({
			cloudBackendHost: getCloudBackendOption("host"),
			localBackendHost: getLocalBackendOption("host")
		});

		const modalOpenValue = computed({
			get: () => props.modelValue,
			set: (value) => emit("update:modelValue", value)
		});

		const sendCustomSettings = () => {
			if (cloudBackendUrl.value)
				setCloudBackendOption("host", cloudBackendUrl.value);

			if (localBackendUrl.value)
				setLocalBackendOption("host", localBackendUrl.value);

			if (localSystemId.value)
				setStoreSystemId(localSystemId.value);

			trackEvent(AppInsightsEvent.CustomBackendSettings, {
				mode: "edit",
				...(cloudBackendUrl.value && { cloudBackendHost: cloudBackendUrl.value }),
				...(localBackendUrl.value && { localBackendHost: localBackendUrl.value })
			});

			modalOpenValue.value = false;
		};

		const resetCustomSettings = () => {
			cloudBackendUrl.value = initialValues.value.cloudBackendHost;
			localBackendUrl.value = initialValues.value.localBackendHost;
			localSystemId.value = storeSystemId.value;

			trackEvent(AppInsightsEvent.CustomBackendSettings, {
				mode: "reset",
				...(cloudBackendUrl.value && { cloudBackendHost: cloudBackendUrl.value }),
				...(localBackendUrl.value && { localBackendHost: localBackendUrl.value })
			});

			modalOpenValue.value = false;
		};

		const onCloseSettings = () => {
			if (cloudBackendUrl.value === "" || !cloudBackendUrl.value)
				cloudBackendUrl.value = getCloudBackendOption("host");
			if (localBackendUrl.value === "" || !localBackendUrl.value)
				localBackendUrl.value = getLocalBackendOption("host");
			if (!localSystemId.value)
				localSystemId.value = storeSystemId.value;
		};

		onBeforeMount(() => {
			cloudBackendUrl.value = getCloudBackendOption("host");
			localBackendUrl.value = getLocalBackendOption("host");
			localSystemId.value = storeSystemId.value;
		});

		return {
			cloudBackendUrl,
			localBackendUrl,
			localSystemId,
			sendCustomSettings,
			resetCustomSettings,
			modalOpenValue,
			onCloseSettings,
			translateKey
		};
	}
});
