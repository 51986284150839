import { defineConnector } from "@qamf/modules-connector";

import supportedLanguages from "../i18n/languages.json";
import use4DAgent from "./agent4D";
import useAuthentication from "./authentication";
import useCloudBackend from "./cloud-rest";
import useLocalBackend from "./local-backend";

type SupportedLanguagesType = typeof supportedLanguages;
export type AppLanguageCodes = keyof SupportedLanguagesType;
const useConnector = defineConnector({
	useCloudBackend,
	useLocalBackend,
	useAuthentication,
	use4DAgent
});
export default useConnector;
