import useConnector from "@app-connector";
import { QModal, useComponentApp } from "@qamf/lighthouse";
import { defineComponent, h, type PropType } from "vue";
import { useRouter } from "vue-router";

export const showModalError = (title: string, description : string) => {
	const errorModal = defineComponent({
		props: {
			onHidden: {
				type: Function as PropType<()=>void>,
				default: () => {}
			},
			onClose: {
				type: Function as PropType<()=>void>,
				default: () => {}
			}
		},
		setup(props) {
			return () => h(
				QModal,
				{
					modelValue: true,
					title,
					hideFooter: true,
					onHidden: props.onHidden,
					onClose: props.onClose
				},
				{
					default: () => description
				}
			);
		}
	});
	const { launch, dispose } = useComponentApp(errorModal, {
		onClose: () => dispose(),
		onHidden: () => location.reload()
	});
	launch();
};

export const showModalAccessDenied = () => {
	const { useAuthentication, useI18n } = useConnector();
	const { translateKey } = useI18n();
	const { logout } = useAuthentication();

	const { launch, dispose } = useComponentApp(
		h(
			QModal,
			{
				modelValue: true,
				title: translateKey("access_denied"),
				okOnly: true,
				hideHeaderClose: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true,
				footerClass: "justify-content-end",
				okTitle: translateKey("logout"),
				size: "sm",
				onOk: async() => {
					dispose();
					await logout();
				}
			},
			{
				default: () => translateKey("access_denied_message")
			}
		)
	);
	launch();
};

export const showModalNotFound = () => {
	const { useI18n } = useConnector();
	const { translateKey } = useI18n();
	const router = useRouter();

	const { launch, dispose } = useComponentApp(
		h(
			QModal,
			{
				modelValue: true,
				title: translateKey("not_found_title"),
				okOnly: true,
				hideHeaderClose: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true,
				footerClass: "justify-content-end",
				okTitle: translateKey("qdesk_home"),
				size: "sm",
				onOk: async() => {
					dispose();
					router.push({ name: "home" });
				}
			},
			{
				default: () => translateKey("not_found_text")
			}
		)
	);
	launch();
};
