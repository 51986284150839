import useConnector from "@app-connector";
import { useGlobalStore } from "@app-shell-store/global";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import { QButton, QSpinner } from "@qamf/lighthouse";
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "InstallPwa",
	components: {
		QButton,
		QSpinner
	},
	setup() {
		const { useI18n, useObservability } = useConnector();
		const { trackEvent } = useObservability();
		const { translateKey } = useI18n();
		const globalStore = useGlobalStore();
		const router = useRouter();
		const isAppInstallAvailable = computed(() => Boolean(globalStore.deferredPrompt));
		const isStandalone = computed(() => globalStore.isStandalone);
		const isSafariDesktop = computed(() => globalStore.isSafariDesktop);
		const isSafariMobile = computed(() => globalStore.isSafariMobile);

		const onInstallButtonClick = async() => {
			globalStore.deferredPrompt?.prompt();
		};

		const onNextButtonClick = async() => {
			trackEvent(AppInsightsEvent.PwaInstallSkipped);
			globalStore.setIsBrowserPreferred(true);
			return router.push({ name: "pairing" });
		};

		onMounted(async() => {
			globalStore.setIsBrowserPreferred(false);
			if (isStandalone.value)
				return router.push({ name: "pairing" });
		});

		return {
			isAppInstallAvailable,
			isSafariDesktop,
			isSafariMobile,
			translateKey,
			onNextButtonClick,
			onInstallButtonClick
		};
	}
});
