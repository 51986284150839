<template>
	<QModal
		id="modal-settings"
		v-model="modalOpenValue"
		:title="translateKey('set_custom_settings')"
		:no-close-on-backdrop="true"
		:cancel-title="translateKey('reset')"
		ok-variant="secondary"
		:ok-title="translateKey('send')"
		@close="onCloseSettings()"
		@click-cancel="resetCustomSettings()"
		@click-ok="sendCustomSettings()"
	>
		<template #modal-title>
			{{ translateKey('set_custom_settings') }}
		</template>
		<div class="d-flex w-100 flex-column row-gap-4">
			<div class="row align-items-center">
				<label
					class="col-3 text-left"
					for="backendUrl"
				>
					{{ translateKey('backend_url') }}
				</label>
				<QFormFieldInput
					id="backendUrl"
					v-model="cloudBackendUrl"
					class="col-9"
					name="backendUrl"
					:placeholder="translateKey('backend_url')"
					:hidden-validation="true"
					autocomplete="off"
					rules="required"
				/>
			</div>
			<div class="row align-items-center">
				<label
					class="col-3 text-left"
					for="localBEUrl"
				>
					{{ translateKey('qdesk_agent_url') }}
				</label>
				<QFormFieldInput
					id="localBEUrl"
					v-model="localBackendUrl"
					class="col-9"
					name="localBEUrl"
					:placeholder="translateKey('qdesk_agent_url')"
					:hidden-validation="true"
					autocomplete="off"
				/>
			</div>
			<div class="row align-items-center">
				<label
					class="col-3 text-left"
					for="systemId"
				>
					{{ translateKey('system_id') }}
				</label>
				<QFormFieldInput
					id="systemId"
					v-model="localSystemId"
					class="col-9"
					name="systemId"
					:placeholder="translateKey('system_id')"
					:hidden-validation="true"
					autocomplete="off"
				/>
			</div>
		</div>
	</QModal>
</template>
<script lang="ts" src="./q-settings.ts"></script>
<style>
#modal-settings .q-form-field {
	margin-bottom: var(--spacer-0);
}
</style>
