import useConnector from "@app-connector";
import { defineComponent } from "vue";

export default defineComponent({
	name: "AccessDenied",
	components: {},
	setup() {
		const { useI18n } = useConnector();
		const { translateKey } = useI18n();

		return {
			translateKey
		};
	}
});
