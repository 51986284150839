export enum AppInsightsEvent {
	BootCompleted = "boot-completed",
	PwaInstallSkipped = "pwa-install-skipped",
	PwaInstalled = "pwa-installed",
	StartedAsStandalonePwa = "started-as-standalone-pwa",
	CustomBackendSettings = "custom-backend-settings",
	FatalError = "fatal-error",
	ModuleRequest = "module-request",
	ModuleDownloaded = "module-downloaded",
	ModuleSetupStart = "module-setup-start",
	ModuleSetupEnd = "module-setup-end",
	ModuleDispose = "module-dispose",
	TerminalPairingStart = "terminal-pairing-start",
	TerminalPairingEnd = "terminal-pairing-end",
	InactivityLogout = "inactivity-logout",
	InactivityStart = "inactivity-start",
	InactivityEnd = "inactivity-end",
}
