import { showModalNotFound } from "@app-utilities/modals";
import { defineComponent, onBeforeMount } from "vue";

export default defineComponent({
	name: "NotFound",
	components: {},
	setup() {
		onBeforeMount(() => {
			showModalNotFound();
		});
	}
});
