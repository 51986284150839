<template>
	<div class="install-pwa container-fluid">
		<div class="row vh-100">
			<div class="col-lg-8 qubica-bg" />
			<div class="col-lg-4 p-3 d-flex justify-content-center align-items-center">
				<div class="d-block py-0 px-5">
					<div class="instructions">
						<h1>Conqueror Hub</h1>
						<p v-if="isSafariDesktop || isSafariMobile || isAppInstallAvailable">
							{{ translateKey("install_pwa_text_intro") }}
						</p>
						<ol v-if="isSafariDesktop">
							<li>{{ translateKey("install_pwa_text_safari_desktop_step_1") }}</li>
							<li>{{ translateKey("install_pwa_text_safari_desktop_step_2") }}</li>
						</ol>
						<ol v-else-if="isSafariMobile">
							<li>{{ translateKey("install_pwa_text_safari_mobile_step_1") }}</li>
							<li>{{ translateKey("install_pwa_text_safari_mobile_step_2") }}</li>
							<li>{{ translateKey("install_pwa_text_safari_mobile_step_3") }}</li>
						</ol>
						<div v-else-if="isAppInstallAvailable">
							<p>{{ translateKey("install_pwa_text_browser") }}</p>
							<br>
							<QButton
								variant="secondary"
								class="d-flex mx-auto mt-5"
								@click="onInstallButtonClick"
							>
								{{ translateKey("install") }}
							</QButton>
						</div>
						<div v-else>
							<p>{{ translateKey("install_pwa_already_installed") }} {{ translateKey("install_pwa_use_the_app") }}</p>
						</div>
						<QButton
							variant="link"
							size="sm"
							class="d-flex mx-auto mt-5"
							@click="onNextButtonClick"
						>
							{{ translateKey("install_pwa_no_thanks") }} {{ translateKey("install_pwa_continue_web") }}
						</QButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" src="./install-pwa.ts"></script>
