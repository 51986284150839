import useConnector from "@app-connector";
import { useGlobalStore } from "@app-shell-store/global";
import { useUserStore } from "@app-shell-store/user";
import { showModalError } from "@app-utilities/modals";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import usePersistentStorage from "@app-utilities/persistent-storage";
import { QButton, QModal, QNavbar, QOverlay, QPopover, QSpinner, QToastOrchestrator, useResponsiveUtilities } from "@qamf/lighthouse";
import { useIdle } from "@vueuse/core";
import { computed, defineComponent, onBeforeMount, onMounted, onUnmounted, ref, watch } from "vue";
import { type RouteRecordRaw, useRoute, useRouter } from "vue-router";

import { routesRegistered } from "./boot";
import type { AppRoute } from "./router";

const logoutCountdownDurationSeconds = 60;
const idleModalTriggerAfterSeconds = 4 * 60 * 60;

export default defineComponent({
	name: "QDeskApp",
	components:
	{
		QOverlay,
		QModal,
		QButton,
		QNavbar,
		QSpinner,
		QPopover,
		QToastOrchestrator
	},
	setup() {
		const { useAppSettings, useObservability, useI18n, useAuthentication } = useConnector();
		const { environment, channel } = useAppSettings();
		const { trackEvent } = useObservability();
		const { translateKey } = useI18n();
		const { getItem } = usePersistentStorage();
		const { logout } = useAuthentication();
		const userStore = useUserStore();
		const globalStore = useGlobalStore();
		const router = useRouter();
		const route = useRoute();
		const responsiveUtils = useResponsiveUtilities();
		const currentUrlPath = route.path;
		const isNative = !globalStore.isBrowser;
		const { idle, reset } = useIdle(idleModalTriggerAfterSeconds * 1000);
		const inactivityModal = ref(false);
		const inactivityTimer = ref<ReturnType<typeof setInterval>>();
		const modalLogoutCountDown = ref(logoutCountdownDurationSeconds);
		const showSettings = ref(false);
		const isAppLoading = ref(true);
		const isTerminalPaired = ref(false);
		const showUserActions = ref(false);
		const showOfflineTooltip = ref(false);
		const modulesNavBarItems = ref<RouteRecordRaw[]>([]);
		const isDevelopmentOrTestingSlot = computed(() => {
			const environmentRegex = /(development|testing)/;
			return environmentRegex.test(environment) && channel === "beta";
		});
		const isUserAuthenticated = computed(() => userStore.userIsAuthenticated);
		const userName = computed(() => userStore.userDetails?.firstName ?? "");
		const showModalSettingDuringPairing = computed(() => {
			const currentRoute = router.currentRoute.value;
			if (currentRoute.name === "pairing" && isDevelopmentOrTestingSlot.value) return true;
			return false;
		});
		const mainContainerClasses = computed(() => isTerminalPaired.value ? "main-wrapper" : "pairing-mode-wrapper");
		const offlineErrorTitle = computed(() => globalStore.isCloudOffline ? translateKey("offline_title_cloud") : globalStore.isClientOffline ? translateKey("offline_title_internet") : "");
		const offlineErrorMessage = computed(() => globalStore.isCloudOffline ? translateKey("offline_message_cloud") : globalStore.isClientOffline ? translateKey("offline_message_internet") : "");

		const initNavBarItems = () => {
			if (!route) return [];

			const routes = router.options.routes.concat(routesRegistered.value) as AppRoute[];
			const enabledRoutes: RouteRecordRaw[] = routes?.filter(r => (r.meta && (r.meta.excludeNavBarItem ? !r.meta.excludeNavBarItem() : true) && (r.meta.showIf ? r.meta.showIf() : true)));
			modulesNavBarItems.value = enabledRoutes.map(r => ({
				...r,
				name: r.name
					? r.name.toString()
						.replace(/\w+/g, m => `${m.charAt(0).toUpperCase()}${m.slice(1)}`)
						.replace(/-/g, " ")
					: ""
			}));
		};

		const currentRouteExists = () => {
			return router.options.routes.find(module => module.path === currentUrlPath);
		};

		const storageEventHandler = async() => {
			isTerminalPaired.value = getItem("terminalPaired") || false;
		};

		const resetTimers = () => {
			trackEvent(AppInsightsEvent.InactivityEnd);
			clearInterval(inactivityTimer.value);
			reset();
		};

		const onLogoutClick = async() => {
			await logout();
		};

		const onKeepLoggedClick = async() => {
			resetTimers();
		};

		const reloadLocationClick = () => {
			location.reload();
		};

		onBeforeMount(async() => {
			if (globalStore.isBrowser) {
				const html = document.documentElement;
				html.setAttribute("data-bs-theme", "dark");
			}
		});

		onMounted(async() => {
			responsiveUtils.init();
			globalStore.initOfflineEvents();

			if (globalStore.isStandalone)
				trackEvent(AppInsightsEvent.StartedAsStandalonePwa);

			isTerminalPaired.value = getItem("terminalPaired") || false;
			window.addEventListener("storage", storageEventHandler, false);

			if (!isTerminalPaired.value) {
				isAppLoading.value = false;
				return;
			}

			await Promise.all([
				userStore.syncUserState(),
				userStore.syncUserInfo()
			]);

			initNavBarItems();

			if (!currentRouteExists()) {
				console.log(`[WARN] Url Path not configured correctly: ${currentUrlPath}`);
				showModalError(translateKey("error"), translateKey("url_path_not_configured", { path: currentUrlPath }));
				return;
			}

			isAppLoading.value = false;
		});

		onUnmounted(() => {
			window.removeEventListener("storage", storageEventHandler);
			responsiveUtils.destroy();
		});

		watch(modalLogoutCountDown, async(countDownValue) => {
			if (countDownValue > 0) return;

			inactivityModal.value = false;
			isAppLoading.value = true;
			const { trackEvent } = useObservability();
			resetTimers();
			trackEvent(AppInsightsEvent.InactivityLogout);
			await logout();
		});

		watch(idle, (idleValue) => {
			if (idleValue && !inactivityModal.value) {
				trackEvent(AppInsightsEvent.InactivityStart);
				modalLogoutCountDown.value = logoutCountdownDurationSeconds;
				inactivityTimer.value = setInterval(() => {
					if (modalLogoutCountDown.value > 0)
						modalLogoutCountDown.value -= 1;
				}, 1000);
				inactivityModal.value = true;
			}
		});

		watch(isUserAuthenticated, async(isLogged) => {
			if (isLogged)
				initNavBarItems();
		});

		return {
			modalLogoutCountDown,
			inactivityModal,
			modulesNavBarItems,
			isBrowser: computed(() => globalStore.isBrowser),
			isDevelopmentOrTestingSlot,
			isAppLoading,
			showSettings,
			isCloudOffline: computed(() => globalStore.isCloudOffline),
			isClientOffline: computed(() => globalStore.isClientOffline),
			isTerminalPaired,
			showModalSettingDuringPairing,
			isUserAuthenticated,
			userName,
			isSmall: computed(() => responsiveUtils.isSmall.value),
			showUserActions,
			showOfflineTooltip,
			translateKey,
			mainContainerClasses,
			offlineErrorTitle,
			offlineErrorMessage,
			onLogoutClick,
			onKeepLoggedClick,
			reloadLocationClick,
			isNative
		};
	}
});
