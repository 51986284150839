import { createRestClient } from "scarlett";

import useConnector from "../index";

const useRestClientBase = createRestClient({
	host: "",
	basePath: "/qdesk",
	throw: true,
	credentials: "omit",
	mode: "cors",
	headers: new Headers({
		"Content-Type": "application/json",
		"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA"
	}),
	responseType: (_, res) => {
		if (res?.status === 500)
			return "text";

		return "json";
	}
});

const useCloud = () => {
	const { useAppSettings } = useConnector();
	const { cloudBackendUrl } = useAppSettings();
	const baseClient = useRestClientBase();

	const usedHost = baseClient.getOption("host");
	if (!usedHost) {
		const host = (__BACKEND_CLOUD_URL__ && __BACKEND_CLOUD_URL__ !== "undefined") ? __BACKEND_CLOUD_URL__ : cloudBackendUrl;
		baseClient.setOption("host", host);
	}

	return baseClient;
};

export default useCloud;
