import Languages from "@app-i18n/languages.json" assert { type: "json" };
import { useValidationI18n } from "@qamf/lighthouse";
import numeral from "numeral";
import { defineStore } from "pinia";
import type { IResponseAny } from "scarlett/lib/interfaces";
import { computed, ref } from "vue";

type LanguageIso = keyof typeof Languages;
type SupportedLanguagesMap = typeof Languages;

const resolveAny: IResponseAny = (prom: Promise<any>) => {
	return new Promise<any>(resolve => {
		prom
			.then((response: any) => resolve([response, null]))
			.catch((err: any) => resolve([null, err]));
	});
};
export const validationDictionary: Record<string, () => Promise<any>> = {
	"en-US": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<any>,
	"en-GB": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<any>,
	"de-DE": () => import("@vee-validate/i18n/dist/locale/de.json") as Promise<any>,
	"fr-FR": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<any>,
	"fr-CA": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<any>,
	"it-IT": () => import("@vee-validate/i18n/dist/locale/it.json") as Promise<any>,
	"es-ES": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<any>,
	"es-MX": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<any>,
	"es-NI": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<any>,
	"nb-NO": () => import("@vee-validate/i18n/dist/locale/nb_NO.json") as Promise<any>,
	"sv-SE": () => import("@vee-validate/i18n/dist/locale/sv.json") as Promise<any>,
	"da-DK": () => import("@vee-validate/i18n/dist/locale/da.json") as Promise<any>,
	"fi-FI": () => import("@vee-validate/i18n/dist/locale/fi.json") as Promise<any>,
	"pl-PL": () => import("@vee-validate/i18n/dist/locale/pl.json") as Promise<any>,
	"cs-CZ": () => import("@vee-validate/i18n/dist/locale/cs.json") as Promise<any>,
	"ru-RU": () => import("@vee-validate/i18n/dist/locale/ru.json") as Promise<any>,
	"vi-VN": () => import("@vee-validate/i18n/dist/locale/vi.json") as Promise<any>,
	"id-ID": () => import("@vee-validate/i18n/dist/locale/id.json") as Promise<any>,
	"hr-HR": () => import("@vee-validate/i18n/dist/locale/hr.json") as Promise<any>,
	"nl-NL": () => import("@vee-validate/i18n/dist/locale/nl.json") as Promise<any>,
	"ja-JP": () => import("@vee-validate/i18n/dist/locale/ja.json") as Promise<any>,
	"zh-CN": () => import("@vee-validate/i18n/dist/locale/zh_CN.json") as Promise<any>
};

const datesDictionary: Record<string, () => Promise<any>> = {
	"en-US": () => import("date-fns/locale/en-US") as Promise<any>,
	"en-GB": () => import("date-fns/locale/en-GB") as Promise<any>,
	"de-DE": () => import("date-fns/locale/de") as Promise<any>,
	"fr-FR": () => import("date-fns/locale/fr") as Promise<any>,
	"fr-CA": () => import("date-fns/locale/fr-CA") as Promise<any>,
	"it-IT": () => import("date-fns/locale/it") as Promise<any>,
	"es-ES": () => import("date-fns/locale/es") as Promise<any>,
	"nb-NO": () => import("date-fns/locale/nb") as Promise<any>,
	"sv-SE": () => import("date-fns/locale/sv") as Promise<any>,
	"da-DK": () => import("date-fns/locale/da") as Promise<any>,
	"fi-FI": () => import("date-fns/locale/fi") as Promise<any>,
	"pl-PL": () => import("date-fns/locale/pl") as Promise<any>,
	"cs-CZ": () => import("date-fns/locale/cs") as Promise<any>,
	"ru-RU": () => import("date-fns/locale/ru") as Promise<any>,
	"vi-VN": () => import("date-fns/locale/vi") as Promise<any>,
	"id-ID": () => import("date-fns/locale/id") as Promise<any>,
	"hr-HR": () => import("date-fns/locale/hr") as Promise<any>,
	"nl-NL": () => import("date-fns/locale/nl") as Promise<any>,
	"ja-JP": () => import("date-fns/locale/ja") as Promise<any>,
	"zh-CN": () => import("date-fns/locale/zh-CN") as Promise<any>
};

const numericDictionary: Record<string, () => Promise<string | null>> = {
	"en-US": () => Promise.resolve(null),
	"en-GB": async() => {
		await import("numeral/locales/en-gb");
		return "en-gb";
	},
	"de-DE": async() => {
		await import("numeral/locales/de");
		return "de";
	},
	"fr-FR": async() => {
		await import("numeral/locales/fr");
		return "fr";
	},
	"fr-CA": async() => {
		await import("numeral/locales/fr-ca");
		return "fr-ca";
	},
	"it-IT": async() => {
		await import("numeral/locales/it");
		return "it";
	},
	"es-ES": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"es-MX": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"es-NI": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"nb-NO": async() => {
		await import("numeral/locales/no");
		return "no";
	},
	"sv-SE": () => Promise.resolve(null),
	"da-DK": async() => {
		await import("numeral/locales/da-dk");
		return "da-dk";
	},
	"fi-FI": async() => {
		await import("numeral/locales/fi");
		return "fi";
	},
	"pl-PL": async() => {
		await import("numeral/locales/pl");
		return "pl";
	},
	"cs-CZ": async() => {
		await import("numeral/locales/cs");
		return "cs";
	},
	"ru-RU": async() => {
		await import("numeral/locales/ru");
		return "ru";
	},
	"vi-VN": async() => {
		await import("numeral/locales/vi");
		return "vi";
	},
	"id-ID": () => Promise.resolve(null),
	"hr-HR": () => Promise.resolve(null),
	"nl-NL": async() => {
		await import("numeral/locales/nl-nl");
		return "nl-nl";
	},
	"ja-JP": async() => {
		await import("numeral/locales/ja");
		return "ja";
	},
	"zh-CN": async() => {
		await import("numeral/locales/chs");
		return "chs";
	}
};

export const useLangStore = defineStore("lang", () => {
	const languageData = ref<SupportedLanguagesMap[LanguageIso] | null>(null);
	const languages: SupportedLanguagesMap = Languages;
	const libLangMap = ref(new Map<string, string>());
	const libDictionary = ref(new Map<string, any>());

	function getSimilarIsoCode(dictionary: any, langToSet: string) {
		const lang = langToSet.split("-")[0];
		return Object.keys(dictionary).find(dictLangKey => dictLangKey.startsWith(lang));
	}
	async function veeValidateLanguage(langToSet: string) {
		return new Promise<void>(async resolve => {
			const { configure, localize, setLocale } = useValidationI18n();
			if (!validationDictionary[langToSet]) {
				const similarLang = getSimilarIsoCode(validationDictionary, langToSet);
				if (!similarLang) return resolve();

				langToSet = similarLang;
			}
			const localesKey = await resolveAny(validationDictionary[langToSet]());
			if (!localesKey[0]) {
				console.warn("Failed to load vee-validate language file");
				return resolve();
			}

			if (langToSet === "en-US") resolve();

			libLangMap.value.set("vee-validate", langToSet);
			libDictionary.value.set("vee-validate", (localesKey as any)[0].messages);

			configure({
				generateMessage: localize(langToSet, { messages: localesKey[0].messages })
			});
			setLocale(langToSet);
			resolve();
		});
	}

	async function dateFnsLanguage(langToSet: string) {
		return new Promise<void>(async resolve => {
			if (!datesDictionary[langToSet]) {
				const similarLang = getSimilarIsoCode(datesDictionary, langToSet);
				if (!similarLang) return resolve();

				langToSet = similarLang;
			}

			const localesKey = await resolveAny(datesDictionary[langToSet]());
			if (!localesKey || !localesKey[0]) {
				console.warn("Failed to load date-fns language file");
				return resolve();
			}

			if (langToSet === "en-US") resolve();

			libLangMap.value.set("date-fns", langToSet);
			libDictionary.value.set("date-fns", (localesKey as any)[0].default);

			resolve();
		});
	}

	async function numeralLanguage(langToSet: string) {
		return new Promise<void>(async resolve => {
			if (!numericDictionary[langToSet]) {
				const similarLang = getSimilarIsoCode(numericDictionary, langToSet);
				if (!similarLang) return resolve();

				langToSet = similarLang;
			}

			const localesKey = await resolveAny(numericDictionary[langToSet]());
			if (!localesKey || !localesKey[0]) {
				console.warn("Failed to load numeral language file");
				return resolve();
			}

			if (langToSet === "en-US") resolve();

			libLangMap.value.set("numeral", langToSet);
			libDictionary.value.set("numeral", (localesKey as any)[0].default);

			numeral.locale(langToSet.split("-")[0]);
			resolve();
		});
	}
	async function setLanguageData(langData: SupportedLanguagesMap[LanguageIso]) {
		languageData.value = langData;
	}

	async function setupI18nApp(langToSet: string) {
		await Promise.all([
			veeValidateLanguage(langToSet),
			dateFnsLanguage(langToSet),
			numeralLanguage(langToSet)
		]);

		const langData = languages[langToSet];
		setLanguageData(langData);
	}

	return {
		setupI18nApp,
		libDictionary,
		timeSeparator: computed(() => languageData.value?.timeSep),
		is24h: computed(() => languageData.value?.is24h),
		textDirection: computed(() => languageData.value?.textDirection)
	};
});
