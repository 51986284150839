interface ITerminalToken {
	iss: string
	exp: number
	iat: number
	scope: string
	sub: string
	jti: string
	aud: string[]
	center_id: number
	company_id: number
	terminal_number: number
	client_id: string
	token_type: string
	oi_tkn_id: string
	oi_au_id: string
}

type TokenUtilities = {
	parseTokenJWT(token: string): ITerminalToken
}

export default function useTokenUtilities(): TokenUtilities {
	const parseTokenJWT = (token: string) => {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function(c) {
			return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(""));

		return JSON.parse(jsonPayload);
	};

	return {
		parseTokenJWT
	};
}
