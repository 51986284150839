type StorageInitiator<T, K extends keyof T> = {
	setItem<K extends keyof T>(name: K, value: T[K]): void;
	getItem<K extends keyof T>(name: K): T[K] | null;
	removeItem(name: K): void;
	clear(): void;
};

type StorageData = {
	browserPreferred: boolean
	terminalPaired: boolean
	terminalNumber: number
	systemId: number
	lastInactiveUser: string
	terminalToken: {
		token_type: string
		expires_in: number
		scope: string
		refresh_token: string
		access_token: string
	},
	terminalAuthInfo: {
		LanIpAddress: string
	},

}

type StorageCategory = keyof StorageData;

export default function usePersistentStorage(storage: Storage = localStorage): StorageInitiator<StorageData, StorageCategory> {
	function getItem<T extends StorageCategory>(name: StorageCategory): StorageData[T] | null {
		const item = storage.getItem(name);
		return item ? JSON.parse(item) : null;
	}

	function setItem<T extends StorageCategory>(name: T, value: StorageData[T]): void {
		return storage.setItem(name, JSON.stringify(value));
	}

	function removeItem(name: StorageCategory): void {
		return storage.removeItem(name);
	}

	function clear(): void {
		return storage.clear();
	}

	return {
		getItem,
		setItem,
		removeItem,
		clear
	};
}
