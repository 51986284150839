import useConnector from "@app-connector";
import type { FunctionalityObj } from "@qamf/modules-connector/dist/interfaces/security";

export enum Functionality {
	Agent4DExperimentalFeature = "Agent4DExperimentalFeature"
}
export type PortalFunctionalities = Record<Functionality, FunctionalityObj>

export const useSecurityUtilities = () => {
	const { useSecurity } = useConnector();
	return useSecurity<keyof PortalFunctionalities>();
};
